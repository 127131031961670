exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-gallery-js": () => import("./../../../src/pages/en/gallery.js" /* webpackChunkName: "component---src-pages-en-gallery-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-galeria-js": () => import("./../../../src/pages/galeria.js" /* webpackChunkName: "component---src-pages-galeria-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-regulamin-polityka-prywatnosci-js": () => import("./../../../src/pages/regulamin-polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-regulamin-polityka-prywatnosci-js" */),
  "component---src-pages-ua-gallery-js": () => import("./../../../src/pages/ua/gallery.js" /* webpackChunkName: "component---src-pages-ua-gallery-js" */),
  "component---src-pages-ua-index-js": () => import("./../../../src/pages/ua/index.js" /* webpackChunkName: "component---src-pages-ua-index-js" */)
}

